import { LoadingProvider } from "components/common/LoadingBoundary";
import { Toasts } from "components/common/Toasts";
import { AuthenticationProvider } from "components/providers/AuthenticationProvider";
import { CommonProviders } from "components/providers/CommonProviders";
import { MyVaultsProvider } from "components/providers/MyVaultsProvider";
import { TitleProvider } from "components/providers/TitleProvider";
import { BrowserRouter } from "components/routing/BrowserRouter";
import { createBrowserHistory } from "history";
import { initializeDebug } from "utils/debug";
import { useColorScheme } from "utils/hooks/useColorScheme";
import { useColorSchemeSwitch } from "utils/hooks/useColorSchemeSwitch";
import { initializeSentry } from "utils/sentry";

import { AppRoutes } from "./AppRoutes";

const history = createBrowserHistory();

initializeSentry();
initializeDebug();

export function App() {
  useColorScheme();
  useColorSchemeSwitch();

  return (
    <LoadingProvider>
      <BrowserRouter navigator={history}>
        <AuthenticationProvider>
          <CommonProviders>
            <TitleProvider>
              <MyVaultsProvider>
                <AppRoutes />
                <Toasts />
              </MyVaultsProvider>
            </TitleProvider>
          </CommonProviders>
        </AuthenticationProvider>
      </BrowserRouter>
    </LoadingProvider>
  );
}
